import { graphql } from "gatsby"
import { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import Layout from "../../components/layout"
import React from "react"
import styles from "./styles.module.scss"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import { Tab, Tabs, Typography } from "@material-ui/core"
import t from '../../services/GetTranslationValue'
import r from '../../services/Router'
import MainSearch from "../../components/main-search/main-search"
import NullIf from "../../services/NullIf"
import RichText from "../../components/text-helpers/rich-text"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import BlockContent from "../../components/text-helpers/portable-text"

const DateSection = ({title, casinos, locale, translations}) => {
  return <div className={`${styles.newCasinosContainer}`}>
    <a name={`casinos_${r.removeAccents(title)}`} id={`casinos_${r.removeAccents(title)}`}>
    <h2 > {title}{t.get(translations, 'currency')} {t.get(translations, 'minimum-deposit-title')}</h2>
    </a>
    <div className="cards-injected">
      <MainSearch bonuses={casinos} locale={locale} />
    </div>
  </div>
}

const Content = ({ currentText, casinos, locale, dates, translations }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  return (
    <div className={`${styles.newCasinos}`}>

      <h1>{currentText.h1Tag} </h1>
      <div className={`${styles.mainTitle}`}>
        {
          currentText._rawHeaderText &&
          <BlockContent blocks={currentText._rawHeaderText} />
        }
      </div>

      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`new-casinos-tabs ${styles.tabs} ${styles.minDepTabs}`}
      >
        {dates.map((x, index) => (
          <Tab
            label={x}
            {...TabsIndex(index)}
            className={`${styles.tabTitle}`}
          />
        ))}

      </Tabs>
      {dates.map((date, index) => {
        const dateBonuses = localizeItem(casinos.filter(x => NullIf.isNotNullOrEmpty(x))
          .filter(x => parseInt(x.minimumDeposit) === parseInt(date)), [locale]);
        return (
          <TabPanel
            value={value}
            index={index}
            className={`${styles.tabContent}`}
          >
            <div className={`${styles.tabBody}`}>
              <DateSection translations={translations} locale={locale} casinos={dateBonuses} title={date} />
            </div>
          </TabPanel>
        )
      })}


      <div className={`${styles.mainTitle}`}>
        {
          currentText._rawCombinedBody &&
          <BlockContent blocks={currentText._rawCombinedBody} />
        }
      </div>

      {
        currentText._rawFooterText &&
        <div className={`${styles.mainTitle}`}>
          <BlockContent blocks={currentText._rawFooterText} />
        </div>
      }
    </div>
  )
}

const MinimumDepositPage = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)

  const { locale, alternateLocaleSlugs } = pageContext
  let { currentText, dates } = data
  let [currentTextNode] = currentText.edges

  let remainingDates = dates.distinct.slice().sort((a, b) => {
      if (parseInt(a) < parseInt(b)) {
        return -1;
      }
      if (parseInt(a) > parseInt(b)) {
        return 1;
      }
      return 0;
    }
  );

  currentTextNode = currentTextNode;

  return (
    <Layout breadcrumbNameOverride={currentTextNode &&
    currentTextNode.node &&
    currentTextNode.node.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoTitle
        }
        description={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoMeta
        }
      bonuses={bonuses}
      />

      {currentTextNode &&
        currentTextNode.node &&
        currentTextNode.node.heroImage && (
          <Img fluid={currentTextNode.node.heroImage.fluid} />
        )}
      {currentText &&
        currentTextNode &&
        currentTextNode.node &&(
          <Content
            currentText={currentTextNode.node}
            translations={translations}
            locale={locale}
            casinos={bonuses}
            dates={remainingDates}
          />
        )}
    </Layout>
  )
}

export default localize(MinimumDepositPage)
export const pageQuery = graphql`
  query MinimumDepositPageQuery {
    dates: allSanityCasino {
      distinct(field: minimumDeposit)
    }
    currentText: allSanityGenericLandingPage(
      filter: { name: { eq: "minimitalletus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }
  }
`
